<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <!-- <ul class="user_data">
      <li>
        <h2>团队人数</h2>
        <p>{{ statistics.total_inviter || 0 }}</p>
      </li>
      <li>
        <h2>推广人数</h2>
        <p>{{ statistics.total_vip_inviter || 0 }}</p>
      </li>
      <li>
        <h2>团队订单</h2>
        <p>{{ statistics.total_order || 0 }}</p>
      </li>
      <li>
        <h2>团队消费</h2>
        <p>{{ statistics.total_amount || 0 }}</p>
      </li>
    </ul> -->
    <ul class="income">
      <li v-for="form in list" :key="form.id">
        <div class="order_number">
          <span>{{ form.order_sn }}</span>
          <b v-if="form.status == 0">未结算(预计{{ form.balance_date }}号) </b>
          <b v-if="form.status == 1" style="color:#666666">已取消</b>
          <b v-if="form.status == 2" style="color:#666666">已退款</b>
          <b v-if="form.status == 3">已结算</b>
        </div>
        <div class="data">
          <div class="title">
            <h2>{{ form.goods_name }}</h2>

            <span>x{{ form.goods_quantity }}</span>
          </div>
          <h3 class="subtitle">{{ form.goods_sku }}</h3>
          <div class="user">
            <img class="tx" :src="form.buyer_avatar" />
            <span class="name">{{ form.buyer_name }}</span>
            <span class="time">{{ form.addtime }}</span>
          </div>

          <div class="price">
            <p>
              佣金：<span>(订单总额：￥{{ form.goods_amount }})</span>
            </p>
            <b>¥{{ form.money }}</b>
          </div>
        </div>
      </li>
    </ul>
  </van-list>
</template>

<script>
import { post } from "@get/http";
import { List } from "vant";

export default {
  components: {
    vanList: List,
  },
  props: {
    tabStatus: {
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      list: [],
      statistics: {},
    };
  },
  created() {},
  watch: {
    tabStatus() {
      this.page = 1;
    },
  },
  methods: {
    async onLoad() {
      let data = await post("/lw.MemberOrderInviter/getList", {
        data: {
          page: this.page,
          pageSize: 20,
          status: this.tabStatus == -1 ? "" : this.tabStatus,
          platform: 0, // 0: 全部 1: 十九匠; 2: 小鹅通
        },
      });
      this.list.push(...data.list);
      this.statistics = data.statistics;
      this.loading = false;
      this.page++;
      // 加载状态结束
      if (data.list && data.list.length != 20) {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.user_data {
  display: flex;
  margin: rem(30) auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 rem(26);
  box-sizing: border-box;
  background: #ffffff;
  width: rem(690);
  height: rem(142);
  box-shadow: 0px rem(4) rem(10) rgba(0, 0, 0, 0.09);
  border-radius: rem(16);
  li {
    h2 {
      color: #666666;
      font-size: rem(24);
    }
    p {
      margin-top: rem(10);
      text-align: center;
      font-size: rem(30);
      color: #222222;
    }
  }
}
.income {
  li {
    box-sizing: border-box;
    padding: 0 rem(24);
    width: rem(690);
    // height: rem(267);
    background: #ffffff;
    border-radius: rem(16);
    margin: 0 auto rem(24);
    &:first-of-type {
      margin-top: rem(24);
    }
    .order_number {
      padding: rem(16) 0;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ededed;

      > span {
        flex: 1;
        color: #222222;
        font-size: rem(24);
        margin-right: rem(30);
        @include ell;
      }
      > b {
        color: #ff4848;
        font-size: rem(24);
      }
    }
    .data {
      padding: rem(24) 0;

      box-sizing: border-box;
      .title {
        display: flex;
        // padding-top: rem(24);
        align-items: center;
        font-size: rem(28);
        color: #222222;
        > h2 {
          @include ell;
          width: rem(555);
        }
        > span {
          flex: 1;
          text-align: right;
        }
      }
    }
    .subtitle {
      color: #666;
      margin-bottom: rem(24);
      font-size: rem(24);
    }
    .user {
      display: flex;
      align-items: center;
      margin-bottom: rem(10);
      font-size: rem(24);
      .tx {
        width: rem(44);
        height: rem(44);
        border-radius: 50%;
        margin-right: rem(10);
      }
      .name {
        color: #000000;
        margin-right: rem(24);
      }
      .time {
        color: #666666;
      }
    }
    .price {
      display: flex;
      font-size: rem(24);
      align-items: center;
      padding-bottom: rem(24);
      p {
        flex: 1;
        color: #222222;
        > span {
          color: #666666;
        }
      }
      > b {
        color: #ff4848;
        font-weight: bold;
      }
    }
  }
}
</style>
