<template>
  <div>
    <van-tabs v-model="active">
      <van-tab title="全部" name="全部">
        <oList :tabStatus="-1"></oList>
      </van-tab>
      <van-tab title="待结算" name="待结算">
        <oList :tabStatus="0"></oList>
      </van-tab>
      <van-tab title="已结算" name="已结算"
        ><oList :tabStatus="3"></oList
      ></van-tab>
      <van-tab title="已退款" name="已退款"
        ><oList :tabStatus="2"></oList
      ></van-tab>
      <van-tab title="纠正流水" name="纠正流水"
        ><oList2 :tabStatus="4"></oList2
      ></van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Tab, Tabs } from "vant";

import oList from "./components/list.vue";
import oList2 from "./components/list2.vue";
export default {
  data() {
    return {
      active: "全部",
    };
  },
  components: {
    oList,
    oList2,
    vanTab: Tab,
    vanTabs: Tabs,
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-tabs__wrap {
    display: block;
  }
}
</style>
